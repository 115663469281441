"use client";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";

type MarketingEmailSubscribeDto = {
  emailAddress: string;
};

export default function MarketingHeroEmailForm() {
  const { register, handleSubmit } = useForm<MarketingEmailSubscribeDto>();
  const router = useRouter();

  const subscribe = (data: MarketingEmailSubscribeDto) => {
    router.push(`/api/auth/signup?email=${data.emailAddress}`);
  };

  return (
    <form onSubmit={handleSubmit(subscribe)}>
      <div className="justify-center items-center mx-auto mb-3 space-y-4 sm:flex lg:justify-start sm:space-y-0 sm:space-x-4">
        <div className="relative">
          <label
            htmlFor="email"
            className="hidden mb-2 text-sm font-medium text-ngray-400"
          >
            Email address
          </label>
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-ngray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
          </div>
          <input
            className="block p-3 pl-10 w-full text-sm text-ngray-400 bg-ngray-800 rounded-lg border border-ngray-300 sm:w-80 xl:w-96 focus:ring-ngray-500 focus:border-ngray-500"
            placeholder="Enter your email"
            type="email"
            id="email"
            required
            {...register("emailAddress")}
          />
        </div>
        <button
          type="submit"
          className="w-full sm:w-auto justify-center text-ngray-50 bg-ngray-700 hover:bg-ngray-800 focus:ring-4 focus:outline-none focus:ring-ngray-300 font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center"
        >
          Subscribe
          <ArrowRightIcon className="w-5 ml-1" />
        </button>
      </div>
    </form>
  );
}
